
import Vue, { PropType } from 'vue';
import { uuid } from '../utils';

export default Vue.extend({
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    name: {
      required: true,
      type: String as PropType<string>,
    },
    detail: {
      required: false,
      type: String as PropType<string>,
    },
  },
  computed: {
    id() {
      return uuid();
    },
  },
  methods: {
    onChange(e: Event) {
      const target = e.target as HTMLInputElement;
      this.$emit('change', {
        name: this.name,
        value:target.checked,
        detail: this.detail? this.detail: undefined
      });
    },
  },
});
