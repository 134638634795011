const finalPageURL = `${process.env.VUE_APP_GABIA_URL}/payments/result`;

interface Params {
  ordernum: string;
}

export default function moveToFinalPage(params: Params) {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', finalPageURL);

  Object.entries(params).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', value);
    form.appendChild(input);
  });

  document.body.appendChild(form);

  form.submit();
}
