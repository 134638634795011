export default function popupWindow(url: string, title: string, w: number, h: number): any {
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const newWindow = window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left,
  );

  if (!newWindow) {
    alert('팝업 차단을 해제해주세요.');
    newWindow!.close();
    return;
  }

  return newWindow;
}
