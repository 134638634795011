import { Component, VueConstructor } from 'vue';

type Identifiers = string | number;

export const enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500,
}

export const enum ApplyType {
  OFFICE_365 = 'Office365',
  CLOUD = 'Cloud',
  NIMS = 'Nims',
  NIMS_MODIFY = 'NimsModify',
  NIMS_EMS = 'NimsEms',
}

/** 결제 수단  */
export const enum PaymentType {
  CARD = 'C',
  ACCOUNT = 'R',
}

/** 결제 수단 (gPay)  */
export const enum GpayMethodType {
  CARD = 9,
  ACCOUNT = 'R',
}
export interface StepBread {
  title: string;
  active: boolean;
}

export type StepBreadType = {
  [K in ApplyType]: StepBread[];
};

export type PackageIdType = {
  [K in ApplyType]?: number[];
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const COMMON_ASYNC_FUNC = async () => {};
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const COMMON_FUNC = () => {};

export type BaseObject<CertainType = any> = { [Identifier in Identifiers]: CertainType };

/** vue + typescript 에서 ref를 사용할때 필요한 타입 */
export type CustomVueRefs<Element> = VueConstructor<Vue & { $refs: Element }>;

/** 컴포넌트를 동적으로 불러올때 사용하는 타입 */
export type DynamicVueComponent = () => Promise<Component>;

/* eslint-disable @typescript-eslint/no-namespace */
//* 각종 event 관련 공통 인터페이스 정의(ex. event.target.value 같은거 사용할 때 타입 정의시 사용)
export namespace VueEvent {
  export interface Input<T extends EventTarget> extends InputEvent {
    target: T;
  }

  export interface Keyboard<T extends EventTarget> extends KeyboardEvent {
    target: T;
  }

  export interface Mouse<T extends EventTarget> extends MouseEvent {
    target: T;
  }

  export interface Drag<T extends EventTarget> extends DragEvent {
    target: T;
    dataTransfer: DataTransfer;
  }

  export interface Error<T extends EventTarget> extends MouseEvent {
    target: T;
  }
}
