import { instance } from '../instance';

const BASEURL = process.env.VUE_APP_GABIA_PROXY_API;

const baseHeaders = {
  accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

export const proxyAPI = instance.create(BASEURL, {
  headers: baseHeaders,
});

export const proxyAPIWithoutInterceptors = instance.createWithoutInterceptors(BASEURL, {
  headers: baseHeaders,
});
