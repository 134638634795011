<template>
  <div class="container">
    <div ref="popover" class="container__popover" :style="positionStyle" :class="popoverClass">
      <slot></slot>
    </div>
    <span class="container__button">
      <slot name="button"></slot>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend(
  {
    name: 'gtris-popover',
    model: {
      prop: 'isActive',
      event: 'toggle'
    },
    props: {
      isActive: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        showAlready: false,
        deactive: true,
        button: null,
        popoverContent: null,
        buttonWidth: 0,
        buttonHeihgt: 0,
        buttonTop: 0,
        buttonLeft: 0,
        popoverContentStyleText: '',
        position: 'absolute',
        margin: 5,
      };
    },
    computed: {
      positionStyle() {
        return {
          position: this.position,
          top: `${this.buttonTop}px`,
          left: `${this.buttonLeft}px`,
        };
      },
      popoverClass() {
        if (!this.showAlready) {
          return {};
        }
        return {
          active: this.isActive,
          deactive: this.deactive,
        };
      },
    },
    watch: {
      isActive(newValue) {
        if (newValue) {
          // 스크롤 불가능하도록 하기 위함
          this.showPopover();
        } else {
          this.hidePopover();
        }
      },
    },
    mounted() {
      const button = this.$slots.button[0].elm;
      this.button = button;
      this.popoverContent = this.$slots.default[0].elm;
      this.popoverContentStyleText = this.popoverContent.style.cssText;
  
      button.addEventListener('click', () => {
        if (!this.showAlready) this.showAlready = true;
        this.$emit('toggle', !this.isActive);
      });
  
      window.addEventListener('click', this.checkClickEvent);
      window.addEventListener('resize', this.emitToggleEvent);
    },
    destroyed() {
      window.removeEventListener('resize', this.emitToggleEvent);
    },
    methods: {
      checkClickEvent(e) {
        const button = this.$slots.button[0].elm;
        const { popover } = this.$refs;
  
        if (this.isActive) {
          // IE 대응을 위해 composedPath 사용
          const path = e.composedPath();
          // eslint-disable-next-line no-restricted-syntax
          for (const node of path) {
            // 현재 발생한 event 경로에 popOver나
            // button 포함되어 있을 때는 꺼지지 않기 위한 조건
            if (node === popover || node === button) {
              e.stopPropagation();
              return;
            }
          }
          this.$emit('toggle', false)
        }
      },
      emitToggleEvent() {
        this.$emit('toggle', false)
      },
      showPopover() {
        this.deactive = false;
        this.attachPosition();
        if (window.innerWidth < 1024) {
          this.$root.$el.style = 'overflow: hidden; height: 100vh;';
          this.makeFullPosition();
        }
      },
      hidePopover() {
        this.deactive = true;
        setTimeout(() => {
          this.deactive = false;
          // TODO side effect 고려해서 수정하기
          this.$root.$el.style = '';
          this.makeDefaultPosition();
        }, 150);
      },
      makeFullPosition() {
        this.position = 'fixed';
        this.buttonTop = 0;
        this.buttonLeft = 0;
        this.popoverContent.style = 'width: 100vw; height: 100vh;';
        document.body.style.overflow = 'hidden';
      },
      makeDefaultPosition() {
        this.position = 'absolute';
        this.buttonTop = this.buttonHeight + this.margin;
        this.buttonLeft = 0;
        this.popoverContent.style = this.popoverContentStyleText;
        document.body.style.overflow = null;
      },
      attachPosition() {
        const { button } = this;
        const { height } = button.getBoundingClientRect();
        this.position = 'absolute';
        this.buttonTop = Math.ceil(height) + this.margin;
      },
    },
  }
) 
</script>

<style lang="scss">
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.container {
  position: relative;
  display: inline-block;
}
.container__popover {
  display: none;
  visibility: hidden;
  position: absolute;
  width: fit-content;
  opacity: 0;
  z-index: 1031;
  @media only screen and (min-width: 1024px) {
    z-index: 1020;
  }
}
.container__button{
  display: inline-block;
  cursor: pointer;
}
.container__popover.active {
  opacity: 0;
  visibility: visible;
  display: block;
  animation: fade-in 0.15s ease-out forwards;
}
.container__popover.deactive {
  opacity: 1;
  visibility: visible;
  display: block;
  animation: fade-out 0.15s ease-out forwards;
}
</style>
;
