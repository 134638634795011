<template>
  <div :class="['input-container', { active: isActive }]">
    <button @click="minus"><i class="fal fa-minus"></i></button>
    <input :value="num" @input="onChange" :disabled="!isActive" />
    <button @click="plus"><i class="fal fa-plus"></i></button>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'minus-plus-number-input',
  model: {
    prop: 'num',
    event: 'change',
  },
  props: {
    name: {
      type: [String, Number],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
    num: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    min(newValue) {
      if (newValue > this.num) {
        this.emitEvent(newValue);
      }
    },
    max(newValue) {
      if (newValue < this.num) {
        this.emitEvent(newValue);
      }
    },
  },
  created() {
    this.emitEvent(this.min);
  },
  methods: {
    plus() {
      if (!this.isActive) {
        this.$emit('deactive');
        return;
      }
      if (this.num + 1 > this.max) {
        this.$emit('max');
        return;
      }
      this.emitEvent(this.num + 1);
    },
    minus() {
      if (!this.isActive) {
        this.$emit('deactive');
        return;
      }
      if (this.num - 1 < this.min) {
        this.$emit('min');
        return;
      }
      this.emitEvent(this.num - 1);
    },
    onChange(e) {
      const processedValue = e.target.value.replace(/((?![0-9]).)/g, '');
      const value = processedValue ? (processedValue * 1 > this.max ? this.max : processedValue * 1) : this.min;
      e.target.value = value;
      this.emitEvent(value);
    },
    emitEvent(value) {
      this.$emit('change', value);
      this.$emit('input', {
        value: value,
        name: this.name,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.input-container {
  display: inline-block;
  color: var(--black);
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  border-radius: 3px;
  padding: 0;
  height: 44px;
  button {
    cursor: default;
    vertical-align: middle;
    height: 100%;
    width: 44px;
  }
  input {
    width: 74px;
    height: 100%;
    padding: 8px 0;
    border: none;
    border-left: 1px solid var(--light-grey);
    border-right: 1px solid var(--light-grey);
    font-size: 16px;
    text-align: center;
  }
  &.active {
    button {
      cursor: pointer;
      background-color: var(--light-blue);
      color: var(--deep-blue);
      &:hover,
      &:active {
        background-color: #ddeafa;
      }
    }
    input {
      color: var(--deep-blue);
    }
  }
}
</style>
