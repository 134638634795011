<template>
  <div>
    <step-bread-crumb />
    <NimsModifyStep01Brain />
  </div>
</template>

<script>
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import { PackageIdList } from '@/assets/data/common/constants';
import { RouterType } from '@/router/types';
import { NIMS_TECHNICAL_GOODS } from '@/assets/data/nims/constants';
import Vue from 'vue';

export default Vue.extend({
  components: {
    NimsModifyStep01Brain: () => import('@/Brain/nims/NimsModifyStep01Brain.vue'),
    StepBreadCrumb,
  },
  data() {
    return {
      office365PackageIdArr: PackageIdList.Office365,
    };
  },
  computed: {
    packageId() {
      return this.$store.state.goodsInfo?.packageId;
    },
    isOffice365Step() {
      return this.office365PackageIdArr.includes(this.packageId);
    },
    isCloudStep() {
      /**
       * 클라우드는 신청 1 ~ 3단계 이며 결제수단만 등록하고 실제 상품신청은 관리콘솔(외부페이지)에서 설정하므로 packageId 등이 없습니다.
       * 해당 조건식은 바뀔수 있어 computed로 분리
       */
      return this.packageId === undefined && this.$route.params.service_type === RouterType.CLOUD;
    },
    isNimsStep() {
      const package_id = this.$route.query.package;
      return this.$route.params.service_type === RouterType.NIMS && !NIMS_TECHNICAL_GOODS[package_id];
    },
    isNimsTechStep() {
      const package_id = this.$route.query.package;
      return this.$route.params.service_type === RouterType.NIMS && !!NIMS_TECHNICAL_GOODS[package_id];
    },
  },
  metaInfo: {
    title: '상품변경 1단계',
  },
});
</script>

<style scoped></style>
