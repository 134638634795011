import { proxyAPI } from '../instance';
import { PROXYALIAS } from '../types';

import { PriceInfo, AddPriceInfo } from '@/api/goods/types';

const proxyAlias = PROXYALIAS.goods;

export const getGoodsPrice = async function (paramsData) {
  let params = {
    url: '/v4/goods/pricing/calculate',
  };

  params = {
    ...params,
    ...paramsData,
  };

  return (await proxyAPI.get(`/auth/${proxyAlias}`, {
    params,
  })) as PriceInfo | null;
};

export const getAddGoodsPrice = async function (paramsData) {
  let params = {
    url: '/v4/goods/pricing/add-change',
  };

  params = {
    ...params,
    ...paramsData,
  };

  return (await proxyAPI.get(`/auth/${proxyAlias}`, {
    params,
  })) as AddPriceInfo | null;
};
