<template>
  <div>
    <step-bread-crumb />
    <microsoft-change-period-result-brain v-if="isMsOffice365Service" />
  </div>
</template>

<script>
import Vue from 'vue';
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';

export default Vue.extend({
  components: {
    StepBreadCrumb,
    MicrosoftChangePeriodResultBrain: () => import('@/Brain/ms365/MicrosoftChangePeriodResultBrain.vue'),
  },
  computed: {
    isMsOffice365Service() {
      return this.$route.params.service_code === 'msoffice365';
    },
  },
  metaInfo: {
    title: '이용 기간 변경 완료',
  },
});
</script>

<style scoped></style>
