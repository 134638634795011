export { default as GtrisModal } from './GtrisModal.vue';

export function installGtrisModal(Vue) {
  let modals = [];
  let activeModals = [];
  let isEventActivated;
  let isSetTimeoutActivated;

  

  window.onpopstate = () => {
    modals = []
    activeModals = [];
  }
  const hide = (name) => {
    if (activeModals.length > 0) {
      if (name) {
        const modal = activeModals.find((modal) => {
          return modal.name === name;
        });
        if (!modal) return;
        modal.close();
        activeModals =
          activeModals.filter((modal) => {
            return modal.name !== name;
          }) || [];
        // modals =
        // modals.filter((modal) => {
        //   return modal.name !== name;
        // }) || [];
          modal.$emit('closed');
      } else {
        const modal = activeModals[activeModals.length - 1];
        modal.close();
        activeModals.pop();
        // modals = modals.filter((prevModal) => {
        //   return prevModal.name !== modal.name;
        // }) || [];
        modal.$emit('closed');
      }

      if (activeModals.length === 0) {
        isSetTimeoutActivated = setTimeout(() => {
          // ie를 위해 표현을 변경하지 마시오.
          document.body.style.cssText = '';
          isSetTimeoutActivated = undefined;
        }, 410);
      }
    }
  };

  if (!Vue.prototype.$GabiaModal) {
    Object.defineProperty(Vue.prototype, '$GabiaModal', {
      get() {
        return {
          add(modal) {
            modals.push(modal);
            if (!isEventActivated) {
              isEventActivated = window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape' || e.key === 'ESC') {
                  if (activeModals.length > 0) {
                    const modal = activeModals[activeModals.length - 1];
                    if (!modal.hideWithHardware) return;
                    hide();
                  }
                }
              });
            }
          },
          show(name) {
            if (isSetTimeoutActivated) {
              clearTimeout(isSetTimeoutActivated);
              isSetTimeoutActivated = undefined;
            }
            const modal = modals.find((modal) => modal.name === name);
            // ie를 위해 표현을 변경하지 마시오.
            if (activeModals.length === 0) {
              document.body.style.cssText = 'overflow: hidden';
            }
            modal.open();
            activeModals.push(modal);
          },
          hide,
        };
      },
    });
  }
}
