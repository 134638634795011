import { NimsEquipment } from '@/api/infra-renew/types';

const SERVER_HOSTING_RENT = { linux: 49685, window: 49686 }; // 서버호스팅 >	서버 임대
const SERVER_HOSTING_ONE_MINUTE = { linux: 49894, window: 49893 }; // 서버호스팅 >	1분 설치서버
const NOT_AVAILAVLE_GOODS = { linux: null, window: null };
const COLOCATION_SERVER = { linux: 49685, window: 49686 };

/**
 * 님스 패키지 packageID
 */
export const NIMS_PACKAGE = {
  49918: SERVER_HOSTING_RENT,
  49919: SERVER_HOSTING_RENT,
  49920: SERVER_HOSTING_RENT,
  49921: SERVER_HOSTING_RENT,
  49922: SERVER_HOSTING_RENT,
  49923: SERVER_HOSTING_RENT,
  49924: SERVER_HOSTING_RENT,
  49925: SERVER_HOSTING_RENT,
  49926: SERVER_HOSTING_RENT,
  49927: SERVER_HOSTING_RENT,
  49729: SERVER_HOSTING_RENT,
  49965: SERVER_HOSTING_RENT,
  49966: SERVER_HOSTING_RENT,
  49967: SERVER_HOSTING_RENT,
  49968: SERVER_HOSTING_RENT,
  49818: SERVER_HOSTING_ONE_MINUTE,
  49820: SERVER_HOSTING_ONE_MINUTE,
  49899: SERVER_HOSTING_ONE_MINUTE,
  49708: NOT_AVAILAVLE_GOODS, // 코로케이션 Quarter Rack
  49734: NOT_AVAILAVLE_GOODS, // 코로케이션 Half Rack
  49735: NOT_AVAILAVLE_GOODS, // 코로케이션 Half Rack
  49937: NOT_AVAILAVLE_GOODS, // 백업
  49938: NOT_AVAILAVLE_GOODS,
  49939: NOT_AVAILAVLE_GOODS,
  49940: NOT_AVAILAVLE_GOODS,
  49941: NOT_AVAILAVLE_GOODS,
  49943: NOT_AVAILAVLE_GOODS,
  49705: COLOCATION_SERVER,
  49731: COLOCATION_SERVER,
  49732: COLOCATION_SERVER,
  49733: COLOCATION_SERVER,
  49736: { linux: 49719, window: null }, // 호스팅 VPS 512M SSD
  38193: { linux: 49803, window: null }, // 호스팅 VPS VPS 1G SSD
  49721: { linux: 49805, window: 49720 }, // 호스팅 VPS VPS 2G SSD
  49737: { linux: 49806, window: 49720 }, // 호스팅 VPS 4G SSD
};

/**
 * standalone 상품은 패키지가 아닌 goodsID 임에 주의
 */
export const NIMS_STANDALONE_GOODS = {
  50089: NOT_AVAILAVLE_GOODS,
  50090: NOT_AVAILAVLE_GOODS,
  50096: NOT_AVAILAVLE_GOODS,
  50269: NOT_AVAILAVLE_GOODS,
  50370: NOT_AVAILAVLE_GOODS,
  50282: NOT_AVAILAVLE_GOODS,
  50281: NOT_AVAILAVLE_GOODS,
  50280: NOT_AVAILAVLE_GOODS,
  51749: NOT_AVAILAVLE_GOODS,
  51750: NOT_AVAILAVLE_GOODS,
  51753: NOT_AVAILAVLE_GOODS,
  51754: NOT_AVAILAVLE_GOODS,
  45681: NOT_AVAILAVLE_GOODS,
  42366: NOT_AVAILAVLE_GOODS,
  42478: NOT_AVAILAVLE_GOODS,
  42016: NOT_AVAILAVLE_GOODS,
  51950: NOT_AVAILAVLE_GOODS,
  50266: NOT_AVAILAVLE_GOODS,
  50156: NOT_AVAILAVLE_GOODS,
};

/**
 * 기술지원 상품
 */
export const NIMS_TECHNICAL_GOODS = {
  49945: NOT_AVAILAVLE_GOODS,
  49946: NOT_AVAILAVLE_GOODS,
  49947: NOT_AVAILAVLE_GOODS,
};

export const CIRCUIT_CODE_ARR = ['STF', 'CTF'];
export const BUNDLE_CODE_ARR = ['BU', 'OS', 'DB', 'HA']; // 용량선택과 같이 큰 차이없이 묶인 상품 패키지 코드
export const MANAGED_CODE_ARR = ['CT'];
export const ONE_MINUTE_ESSENTIAL_GOODSCODE_ARR = ['OS', 'SC', 'EMS'];
export const VPS_PACKAGE_ESSENTIAL_GOODSCODE_ARR = ['OS', 'SC'];
export const ONE_MINUTE_PACKAGE_IDS = [49818, 49820, 49899];
export const VPS_PACKAGE_IDS = [49736, 38193, 49721, 49737];
/**
 *
 *  님스 신청 개선에 의해 그룹이 없는경우 1~12개월
 *  그룹이 있는경우 그룹 만기일로 변경됨
 *  결제 방식은 이제 상관 없음
 */

export const NORMAL_PREPAYMENT_OPTION = [
  { label: '1개월', value: '1' },
  { label: '3개월', value: '3' },
  { label: '6개월', value: '6' },
  { label: '12개월', value: '12' },
];

/**
 * 모달 아이디 (추가 상품)
 */
export const ADDITIONAL_GOODS_MODAL_IDS = ['ct-modal', 'sc-modal', 'ems-modal', 'bu-modal', 'vc-modal', 'wi-modal'];

export enum GoodsPayType {
  PRE_PAID_NOMAL = 'PP',
  PRE_PAID_REGULAR = 'PA',
  DEPOSIT_PAID_NOMAL = 'DP',
  DEPOSIT_PAID_REGULAR = 'DA',
}

export const NIMS_EQUIP_DEFAULT: NimsEquipment = {
  seqno: '',
  alias: '',
  goods_id: 0,
  goods_name: '',
  idc: { label: '', code: '' },
  server_ip: '',
  main_goods_view: {
    mid_class_code: {
      id: '',
      code: '',
      label: '',
    },
    status: {
      code: '',
      label: '',
    },
    sub_class_code: {
      id: '',
      code: '',
      label: '',
    },
    apply_date: '',
  },
};

export const ERROR_CODE_OBJ = {
  WRONG_PARAMS: '파라메터가 올바르지 않습니다.',
  NO_MATCHING_INFO: '연결된 정보가 존재하지 않습니다.',
  EXIST_REFUNDS_INFO: '환불정보가 이미 존재합니다.',
};
