export const getDateStringFromFormat = (format) => {
  const dateObj = new Date();
  const yyyy = dateObj.getFullYear().toString();
  const mm = (dateObj.getMonth() + 1).toString();
  const dd = dateObj.getDate().toString();
  const hh = dateObj.getHours().toString();
  const ii = dateObj.getMinutes().toString();
  const ss = dateObj.getSeconds().toString();

  return format
    .replace(/yyyy/g, yyyy)
    .replace(/mm/g, mm[1] ? mm : '0' + mm[0])
    .replace(/dd/g, dd[1] ? dd : '0' + dd[0])
    .replace(/hh/g, hh[1] ? hh : '0' + hh[0])
    .replace(/ii/g, ii[1] ? ii : '0' + ii[0])
    .replace(/ss/g, ss[1] ? ss : '0' + ss[0]);
};

export const getCookie = (name) => {
  const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return value ? value[2] : null;
};

/**
 *
 * @param {
 *  format : String, (yyyy , yyyy-mm-dd ) ,
 *  setDate : String (time string ex> iso 8601 , 2022-10-22 .. )
 * } timeObj
 * @returns String
 */

export const getDateStringFromObject = (timeObj) => {
  const dateObj = timeObj.setDate ? new Date(timeObj.setDate) : new Date();
  const yyyy = dateObj.getFullYear().toString();
  const mm = (dateObj.getMonth() + 1).toString();
  const dd = dateObj.getDate().toString();
  const hh = dateObj.getHours().toString();
  const ii = dateObj.getMinutes().toString();
  const ss = dateObj.getSeconds().toString();

  return timeObj.format
    .replace(/yyyy/g, yyyy)
    .replace(/mm/g, mm[1] ? mm : '0' + mm[0])
    .replace(/dd/g, dd[1] ? dd : '0' + dd[0])
    .replace(/hh/g, hh[1] ? hh : '0' + hh[0])
    .replace(/ii/g, ii[1] ? ii : '0' + ii[0])
    .replace(/ss/g, ss[1] ? ss : '0' + ss[0]);
};

/**
 *
 * @param {*} obj
 * @returns boolean
 * @description object가 비었는지 체크
 */

export const isEmptyObject = (obj) => {
  return obj.constructor === Object && Object.keys(obj).length === 0;
};

export const deepCopyObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const isEmail = (value) => {
  //const pattern = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{2,4}$/;  //기존 정규식
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u;

  // 정규식 패턴에 맞는지 확인
  const isPatternValid = pattern.test(value);

  // @ 다음에 바로 .이 오지 않는지 확인
  const isNotStartingWithDotAfterAt = !/@\./.test(value);

  // .이 연속으로 나오지 않는지 확인
  const isNoConsecutiveDots = value.indexOf('..') === -1;

  // .로 시작하지 않고 끝나지 않는지 확인
  const isNotStartingOrEndingWithDot = !value.startsWith('.') && !value.endsWith('.');

  return isPatternValid && isNoConsecutiveDots && isNotStartingOrEndingWithDot && isNotStartingWithDotAfterAt;
};

export const getNumOrigin = (value) => value.replace(/[^0-9]/g, '');

export const getPhoneNum = (value = '') => {
  const phone_num = getNumOrigin(value);

  if (phone_num.length < 4) {
    return phone_num;
  }

  if (phone_num.length < 7) {
    return phone_num.substring(0, 3) + '-' + phone_num.substring(3);
  }

  if (phone_num.length < 10) {
    return phone_num.substring(0, 2) + '-' + phone_num.substring(2, 5) + '-' + phone_num.substring(5);
  }

  if (phone_num.length < 11) {
    const regex = /^02/;

    if (regex.test(phone_num)) {
      return phone_num.substring(0, 2) + '-' + phone_num.substring(2, 6) + '-' + phone_num.substring(6);
    }
    return phone_num.substring(0, 3) + '-' + phone_num.substring(3, 6) + '-' + phone_num.substring(6);
  }

  if (phone_num.length < 12) {
    return phone_num.substring(0, 3) + '-' + phone_num.substring(3, 7) + '-' + phone_num.substring(7);
  }

  return phone_num.substring(0, 4) + '-' + phone_num.substring(4, 8) + '-' + phone_num.substring(8);
};

export const isPhoneNum = (value) => {
  const pattern = /^([0-9]{2,4})-([0-9]{3,4})-([0-9]{4})$/;
  return pattern.test(value);
};

export const nimsGoodsTypeStructureGenerator = (list, param) => {
  list.goods_pricing_list.forEach((item) => {
    item.carve_code = param.carve_code;
    item.carve_code_str = param.carve_code_str;
    item.price.final_supply_price += item.price.extra_days_final_supply_price;

    if (item.price.pricing_rule_list === null && item.price.extra_days_final_supply_price !== 0) {
      item.price.pricing_rule_list = [];
      item.price.pricing_rule_list.push({
        display_rule_name: '만기일 맞춤 비용',
        display_value: '원',
        supply_rule_price: item.price.extra_days_final_supply_price,
      });
    } else if (item.price.pricing_rule_list && item.price.extra_days_final_supply_price !== 0) {
      item.price.pricing_rule_list.push({
        display_rule_name: '만기일 맞춤 비용',
        display_value: '원',
        supply_rule_price: item.price.extra_days_final_supply_price,
      });
    }
  });
  return list;
};

export const maintenanceAlert = (startTime, endTime, redirectUrl, messages, messageKey) => {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  // 점검 시간 내에 있는지 확인합니다.
  if (now >= start && now <= end) {
    if (messages[messageKey]) {
      alert(messages[messageKey]);
    } else {
      alert('시스템 이용에 문제가 발생하였습니다.\n가비아 고객센터(1544-4370)로 연락하시기 바랍니다.\n감사합니다.');
    }

    window.location.href = redirectUrl;
    return false;
  }
  return true;
};
