<template>
  <bill-layout>
    <form-layout>
      <template v-slot:title>
        <i :class="formTitle.detail.iconClass"></i>
        {{ formTitle.detail.text }}
      </template>
      <template v-slot:content>
        <table-layout>
          <column-layout>
            <template v-slot:title>
              <span class="title-mobile">서비스</span>
            </template>
            <template v-slot:content>Microsoft 365 - {{ templateState.domain }}</template>
          </column-layout>
          <column-layout>
            <template v-slot:title>
              <div style="max-width: 130px" class="title-mobile">
                {{ goodsName }}
              </div>
            </template>
            <template v-slot:content>
              <minus-plus-number-input isActive :min="1" :max="300" :num="goodsQuantity" @change="onNumberChange" />
            </template>
          </column-layout>
          <column-layout>
            <template v-slot:title>
              <span class="title-mobile">
                신청 기간
                <info-popover v-if="!isNewApplication">
                  <template v-slot:title> 이용 기간 안내 </template>
                  <template v-slot:content>
                    <ul>
                      <li>현 시점부터 만기일까지 남은 서비스 기간만큼 추가됩니다.</li>
                      <li>1개월 미만은 일별로 계산하여 비용을 청구합니다.</li>
                    </ul>
                  </template>
                </info-popover>
              </span>
            </template>
            <template v-slot:content>
              <template v-if="isNewApplication">
                <select @change="onChangePeriod" :value="formState.selectedAddChangePeriod">
                  <option v-for="(option, index) in goodsIdPair" :value="option" :key="option">
                    <template v-if="index === 0">1개월</template>
                    <template v-else-if="index === 1">1년</template>
                  </option>
                </select>
              </template>
              <template v-else> {{ templateState.detailedTerm }} </template>
            </template>
          </column-layout>
        </table-layout>
        <div class="info-list" v-if="isNewApplication">
          새로운 타입의 라이선스를 신청합니다. 이용 중인 Microsoft 365 서비스와 다른 새로운 만기일이 생성됩니다.
        </div>
        <div class="info-list" v-if="isNewApplication">
          라이선스의 만기일은 결제 및 관리 정보 입력 후 서비스 세팅이 완료되면 확인하실 수 있습니다.
        </div>
        <li class="info-list">
          라이선스 추가가 완료되면 96시간 이내에만 해지 신청이 가능합니다. 96시간이 지나면 라이선스 취소가 불가하오니
          이점 유의하여 주시기 바랍니다.
        </li>
      </template>
    </form-layout>
  </bill-layout>
</template>

<script>
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import Vue from 'vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import MinusPlusNumberInput from '@/components/inputs/MinusPlusNumberInput.vue';
import InfoPopover from '@/components/common/InfoPopover/InfoPopover.vue';

export default Vue.extend({
  components: { FormLayout, BillLayout, TableLayout, ColumnLayout, MinusPlusNumberInput, InfoPopover },
  model: {
    prop: 'formState',
    event: 'change',
  },
  props: {
    formState: Object,
    isNewApplication: Boolean,
    templateState: Object,
    goodsIdPair: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { formTitle };
  },
  computed: {
    goods() {
      return this.formState.goodsList?.[0];
    },
    goodsName() {
      if (this.goods) {
        return this.goods.name;
      }
      return '';
    },
    goodsQuantity() {
      return this.goods ? this.goods.number : 0;
    },
  },
  methods: {
    onNumberChange(e) {
      this.$console.log(e);
      this.$emit('change', {
        ...this.formState,
        goodsList: [
          {
            ...this.formState.goodsList[0],
            number: e,
          },
        ],
      });
    },
    // 신규 라이선스 추가시 1개월 / 1년형 선택
    onChangePeriod(e) {
      this.$emit('change', {
        ...this.formState,
        selectedAddChangePeriod: Number(e.target.value),
      });
    },
  },
});
</script>

<style scoped>
select {
  width: 164px;
  height: 44px;
  padding-right: 30px;
  padding-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background: url(https://static.gabia.com/responsive/assets/common/images/caret-down-solid.svg) no-repeat right 10px
    center;
  background-size: 10px 16px;
  border: 1px solid #c7cbcc;
  background-color: #fff;
  border-radius: 3px;
  color: #707070;
  font-size: 1rem;
}
</style>
