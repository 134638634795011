import { proxyAPI } from '../instance';
import { PROXYALIAS } from '../types';
import { Service } from './types';

const proxyAlias = PROXYALIAS.service;

export const getServiceInfo = async (serviceSeq): Promise<Service | null> => {
  const params = {
    url: `/v1/service/${serviceSeq}`,
  };

  const res = await proxyAPI.get<any, { service: any }>(`/auth/${proxyAlias}`, {
    params,
  });

  if (res) {
    return res.service;
  }

  return res;
};

export const getOperatingServiceInfo = async (serviceSeq): Promise<Service | null> => {
  const params = {
    url: `/v1/service-operation/${serviceSeq}`,
  };

  const res = await proxyAPI.get<any, { service: any }>(`/auth/${proxyAlias}`, {
    params,
  });

  if (res) {
    return res.service;
  }

  return res;
};
