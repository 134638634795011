<template>
  <transition name="fade" appear>
    <div class="global-loading" v-if="isVisible">
      <i class="fa-regular fa-spinner fa-spin spinner"></i>
    </div>
  </transition>
</template>

<script>
const $body = document.querySelector('body');

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    show() {
      this.isVisible = true;
      $body.style.cssText = `overflow-y: hidden;`;
    },
    hide() {
      this.isVisible = false;
      $body.style.cssText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.global-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;

  .spinner {
    color: #fff;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
