import axios from 'axios';

const baseHeaders = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
};

const baseConfig = {
  headers: baseHeaders,
  withCredentials: true,
};

const instance = {
  createWithoutInterceptors(baseURL: string, customConfig: any = {}) {
    if (customConfig.hasOwnProperty('headers')) {
      customConfig.headers = {
        ...baseConfig.headers,
        ...customConfig.headers,
      };
    }

    const instance = axios.create({
      baseURL: baseURL,
      ...baseConfig,
      ...customConfig,
    });

    return instance;
  },
  create(baseURL: string, customConfig: any = {}) {
    if (customConfig.hasOwnProperty('headers')) {
      customConfig.headers = {
        ...baseConfig.headers,
        ...customConfig.headers,
      };
    }

    const instance = axios.create({
      baseURL: baseURL,
      ...baseConfig,
      ...customConfig,
    });

    instance.interceptors.response.use(
      (res) => res.data,
      (e) => {
        return null;
      },
    );

    return instance;
  },
};

export { instance };
