/**
 * 개별로 적는것도 좋지만 가져올때 너무 길어지니 카데코리를 추가해서 부르는걸 권장
 *
 * export const INFO_CHECK = '정보 확인';
 * export const SERVICE_BILL_PAYMENT = '서비스 비용 결재';
 * export const SERVICE_BILL_PAYMENT2 = '서비스 비용 결재2';
 *
 * import { INFO_CHECK , SERVICE_BILL_PAYMENT , SERVICE_BILL_PAYMENT2  } from './messages.ts' 도 괜찮지만
 * import { STEP_MESSAGE } from  './messages.ts' 이런식으로 부르는게 깔끔해 보이긴해요
 */

export const STEP_MESSAGE = {
  APPLY_INFO_INSERT: '신청 정보 입력',
  APPLY_INFO_CHECK: '신청정보 확인',
  SERVICE_PAYMENT: '서비스 비용 결제',
  ADMIN_INFO_INSERT: '관리 정보 입력',
  INFO_CHECK: '정보 확인',
  INSERT_INFO_CHECK: '입력 정보 확인',
  MODIFY_PRODUCT_SELECT: '변경상품 선택',
  REFUND_INFO_INSERT: '환불 정보 입력',
};
