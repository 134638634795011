
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import MicrosoftAdd01Template from '@/templates/ms365/MicrosoftAdd01Template.vue';
import Vue from 'vue';
import { getGoodsInfo } from '@/api/goods';
import { getGoodsPrice, getAddGoodsPrice } from '@/api/proxy/goods';
import { createAddOrder, createNewOrder } from '@/api/proxy/order';
import { moveToBillingPage } from '@/utils';
import { GoodsPriceInfo } from '@/api/goods/types';
import { getOperatingServiceInfo } from '@/api/proxy/service';
import { getUsedLicenseWithDomain } from '@/api/proxy/gapi';
import { ProcessedGoods } from '@/types/common/goods';

const BUSINESS_STANDARD = [52501, 52502]; // Microsoft 365 Business Standard
const BUSINESS_BASIC = [52499, 52500]; // Microsoft 365 Business Basic
const BUSINESS_APP = [52503, 52504]; // 비즈니스용 Microsoft 365 앱

const MS365_GOODS = [...BUSINESS_BASIC, ...BUSINESS_STANDARD, ...BUSINESS_APP];
const MS365_GOODS_PAIR = [BUSINESS_BASIC, BUSINESS_STANDARD, BUSINESS_APP];

// 같은 타입의 라이선스 모음
const GET_SAME_TYPE_LICENSE = MS365_GOODS_PAIR.reduce((acc, [id1, id2]) => {
  acc[id1] = id2;
  acc[id2] = id1;
  return acc;
}, {});

export default Vue.extend({
  components: { CalculatorScreen, MicrosoftAdd01Template },
  data() {
    return {
      // 신규 라이선스 추가인지, 기존 라이선스 추가인지 구분하기 위한 변수
      isNewApplication: undefined as boolean | undefined,
      oldServiceSeq: undefined as number | undefined,
      formState: {
        goodsList: [{}] as ProcessedGoods[],
        selectedAddChangePeriod: 0,
      },
      templateState: {
        domain: '',
        detailedTerm: '',
      },
      selectedGoodsPriceInfo: {
        totalPrice: 0 as number | undefined,
        totalPriceWithVat: 0 as number | undefined,
        vat: 0 as number | undefined,
        selectedGoods: undefined as GoodsPriceInfo[] | undefined,
        totalRulePrice: 0 as number | undefined,
        cutPrice: 0 as number | undefined,
      },
    };
  },
  computed: {
    goodsList(): any {
      return this.formState.goodsList;
    },
    // 추가할 상품 번호
    goodsId(): number {
      return this.$store.state.goodsInfo.goodsId;
    },
    // 통합 서비스 번호
    serviceId(): number {
      return this.$store.state.goodsInfo.serviceId;
    },
    goodsIdPair(): number[] {
      // 1개월 / 1년 - Microsoft 365 Business Standard
      if (BUSINESS_STANDARD.includes(this.goodsId)) {
        return BUSINESS_STANDARD;
      }
      // 1개월 / 1년 - Microsoft 365 Business Basic
      else if (BUSINESS_BASIC.includes(this.goodsId)) {
        return BUSINESS_BASIC;
      }
      // 1개월 / 1년 - 비즈니스용 Microsoft 365 앱
      else if (BUSINESS_APP.includes(this.goodsId)) {
        return BUSINESS_APP;
      } else {
        return [];
      }
    },
  },
  watch: {
    async goodsList(newValue) {
      if (typeof this.isNewApplication === 'undefined') {
        return;
      }
      if (this.isNewApplication) {
        await this.getNewPriceData(newValue);
      } else {
        await this.getAddedPriceData(newValue);
      }
    },
  },
  async created() {
    if (!MS365_GOODS.includes(this.goodsId)) {
      this.blockUser();
      return;
    }

    // 운영중인 서비스 정보
    const serviceInfo = await getOperatingServiceInfo(this.serviceId);

    if (!serviceInfo) {
      if (process.env.VUE_APP_MODE !== 'production') {
        alert('test:현재 접근하려는 service와 로그인된 사용자가 일치하지 않습니다. 재로그인 후 다시 시도해주십시오.');
      }
      this.blockUser();
      return;
    }

    this.$console.log(serviceInfo, '통합 서비스 seq로 조회한 내용');

    this.templateState.domain = serviceInfo?.service_main_goods_list[0].domain || '';
    this.oldServiceSeq = serviceInfo?.service_main_goods_list[0].service_seqno;

    // 도메인으로 사용중인 license 조회
    const usedLicense = await getUsedLicenseWithDomain(this.templateState.domain);
    if (usedLicense) {
      if (usedLicense[GET_SAME_TYPE_LICENSE[this.goodsId]].service.usage) {
        /*
          동일 타입의 다른 기간 상품이 이미 사용중이면 기존 라이선스 추가로 이동, 동일 타입의 다른 기간 상품으로 링크 이동
         */
        const isConfirm = confirm('해당 도메인으로 이미 사용중인 라이선스가 있습니다.\n라이선스를 추가하시겠습니까?');

        if (isConfirm) {
          window.location.href = `${process.env.VUE_APP_APPLICATION_URL}/add-goods?goods_id=${
            GET_SAME_TYPE_LICENSE[this.goodsId]
          }&service_id=${this.serviceId}`;
        } else {
          window.location.href = process.env.VUE_APP_GABIA_URL;
        }
        return;
      }

      const usedLicenseCoincideWithGoodsId = Object.entries(usedLicense).find(([_, value]) => {
        return value.id === this.goodsId;
      })?.[1];

      // 현재 goodsId가 사용중이 아니면
      if (!usedLicenseCoincideWithGoodsId.service.usage) {
        this.isNewApplication = true;
      }
      // 현재 goodsId가 사용중이면
      else {
        this.isNewApplication = false;
        const stepArr = [...this.$store.state.stepInfo.stepArr];
        stepArr.splice(2, 1);
        this.$store.commit('setStepInfo', { ...this.$store.state.stepInfo, stepArr });
      }
    }

    this.$console.log(usedLicense, '도메인에 연결된 상품 조회 결과');

    const goodsInfo = await getGoodsInfo({
      'options[only_sales_goods]': 'N',
      'goods_list[0][id]': this.$store.state.goodsInfo.goodsId,
    });

    this.$console.log(goodsInfo?.goods_list?.[0].id);
    this.$console.log(goodsInfo?.goods_list?.[0].name);

    if (goodsInfo?.goods_list?.[0]) {
      this.formState.goodsList = [
        {
          id: goodsInfo?.goods_list?.[0].id,
          name: goodsInfo?.goods_list?.[0].expose_name,
          termUnit: goodsInfo?.goods_list?.[0].term_unit_code.code,
          number: 1,
        },
      ];
      this.formState.selectedAddChangePeriod = goodsInfo?.goods_list?.[0].id;
    }

    this.$nextTick(() => {
      this.$GlobalLoading.hide();
    });
  },
  methods: {
    async onClickNextStepBtn() {
      this.$GlobalLoading.show();

      const orderData = this.isNewApplication ? await this.createNewOrder() : await this.createAddedOrder();

      this.$console.log(orderData);

      if (orderData) {
        if (
          confirm(
            'Microsoft사의 정책에 따라 라이선스 추가는 세팅 후 96시간 이내에만 환불이 가능하며, 이후에는 환불이 불가합니다. 서비스를 신청하시겠습니까?',
          )
        ) {
          if (this.isNewApplication) {
            moveToBillingPage({
              ordernum: orderData.order_number,
              return_url: `${process.env.VUE_APP_APPLICATION_URL}/management-information?service_id=${orderData.seqno}`,
              back_url: `${process.env.VUE_APP_APPLICATION_URL}/add-goods?goods_id=${this.$store.state.goodsInfo.goodsId}&service_id=${this.$store.state.goodsInfo.serviceId}`,
            });
          } else {
            moveToBillingPage({
              ordernum: orderData.order_number,
              return_url: `${process.env.VUE_APP_GABIA_URL}/payments/result`,
              back_url: `${process.env.VUE_APP_APPLICATION_URL}/add-goods?goods_id=${this.$store.state.goodsInfo.goodsId}&service_id=${this.$store.state.goodsInfo.serviceId}`,
            });
          }
        }
      } else {
        alert('주문서 생성에 실패하였습니다.');
      }

      this.$GlobalLoading.hide();
    },
    // 상품 추가 가격 조회
    async getAddedPriceData(newValue) {
      const serviceId = this.$store.state.goodsInfo.serviceId;
      const goodsList = newValue as ProcessedGoods[];

      const goodsPriceParams = {
        'data[service[seqno]]': serviceId,
      };

      let totalGoodsQuantity = 0;

      goodsList?.forEach((goods, idx) => {
        if (goods.number > 0) {
          totalGoodsQuantity += goods.number;
          goodsPriceParams[`data[add_goods_list[${idx}][goods_type]]`] = 'main';
          goodsPriceParams[`data[add_goods_list[${idx}][add_goods_id]]`] = goods.id;
          goodsPriceParams[`data[add_goods_list[${idx}][quantity]]`] = goods.number;
        }
      });

      if (totalGoodsQuantity === 0) {
        this.selectedGoodsPriceInfo = {
          totalPrice: 0,
          totalPriceWithVat: 0,
          vat: 0,
          selectedGoods: undefined,
          totalRulePrice: 0,
          cutPrice: 0,
        };
        this.$console.log(this.selectedGoodsPriceInfo, '0개인 경우');
        return;
      }

      const priceData = await getAddGoodsPrice(goodsPriceParams);

      this.$console.log(priceData);

      if (priceData) {
        this.selectedGoodsPriceInfo = {
          totalPrice: priceData.total_pricing.total_price,
          vat: priceData.total_pricing.vat,
          totalPriceWithVat: priceData.total_pricing.total_price_with_vat,
          selectedGoods: priceData.goods_pricing_list,
          totalRulePrice: priceData?.total_pricing.total_rule_price,
          cutPrice: priceData?.total_pricing.cut_price,
        };

        if (!this.templateState.detailedTerm) {
          const today = new Date();
          const monthString = ('0' + (today.getMonth() + 1).toString()).slice(-2);
          const dateString = ('0' + today.getDate().toString()).slice(-2);
          this.templateState.detailedTerm = `
              ${priceData.goods_pricing_list[0].goods_order_term_detail.goods_order_term_display} (
                시작일 ${today.getFullYear()}-${monthString}-${dateString} ~ 만기일 ${
            priceData.goods_pricing_list[0].expire_date.split(' ')[0]
          }
              )
            `;
        }
      }
    },
    // 상품 신규 신청 가격 조회 API
    async getNewPriceData(newValue) {
      this.$console.log(newValue);
      const goodsList = newValue as ProcessedGoods[];

      const goodsPriceParams = {
        ['data[order_situation]']: 'application',
      };

      let totalGoodsQuantity = 0;

      goodsList?.forEach((goods, idx) => {
        if (goods.number > 0) {
          totalGoodsQuantity += goods.number;
          goodsPriceParams[`data[goods_list[${idx}][quantity]]`] = goods.number;
          goodsPriceParams[`data[goods_list[${idx}][id]]`] = this.formState.selectedAddChangePeriod;
          goodsPriceParams[`data[goods_list[${idx}][order_term]]`] = 1;
        }
      });

      if (totalGoodsQuantity === 0) {
        this.selectedGoodsPriceInfo = {
          totalPrice: 0,
          totalPriceWithVat: 0,
          vat: 0,
          selectedGoods: undefined,
          totalRulePrice: 0,
          cutPrice: 0,
        };
        this.$console.log(this.selectedGoodsPriceInfo, '0개인 경우');
        return;
      }

      const priceData = await getGoodsPrice(goodsPriceParams);

      this.$console.log(priceData);

      if (priceData) {
        this.selectedGoodsPriceInfo = {
          totalPrice: priceData.total_pricing.total_price,
          vat: priceData.total_pricing.vat,
          totalPriceWithVat: priceData.total_pricing.total_price_with_vat,
          selectedGoods: priceData.goods_pricing_list,
          totalRulePrice: priceData?.total_pricing.total_rule_price,
          cutPrice: priceData?.total_pricing.cut_price,
        };
      }
    },
    // 신규 주문서 생성 API
    async createNewOrder() {
      this.$console.log('====== 주문서 생성에 필요한 정보 =====');
      this.$console.log(this.formState.goodsList);
      this.$console.log('================================');

      const willOrderedGoodsList = this.formState.goodsList?.reduce((prev: any, cur: any) => {
        if (cur) {
          return [
            ...prev,
            {
              goods_list: [
                {
                  id: this.formState.selectedAddChangePeriod,
                  quantity: cur.number,
                  order_term: {
                    unit: this.goodsIdPair.indexOf(this.formState.selectedAddChangePeriod) === 0 ? 'month' : 'year', // 신청 기간 변경에 따라 termUnit 변경
                    term: 1,
                  },
                  settlement_option: {
                    dep_item: 'MS0',
                  },
                },
              ],
            },
          ];
        } else return prev;
      }, []);

      this.$console.log(willOrderedGoodsList, 'will ordered goods list');

      const orderData = await createNewOrder({
        order_page: 'application',
        checkout: {
          service_list: willOrderedGoodsList,
          domain_data: {
            domain: this.templateState.domain,
          },
          checkout_option: {
            origin: 'gabia',
          },
          total_price: this.selectedGoodsPriceInfo.totalPriceWithVat,
        },
      });

      return orderData;
    },
    // 추가/변경 주문서 생성 API
    async createAddedOrder() {
      this.$console.log('====== 주문서 생성에 필요한 정보 =====');
      this.$console.log(this.formState.goodsList);
      this.$console.log('================================');

      const willAddedGoodsList = this.formState.goodsList?.map((goods) => {
        return {
          goods_type: 'main',
          add_goods_id: goods.id,
          quantity: goods.number,
          sort_order: 0,
        };
      });
      const orderData = await createAddOrder({
        checkout: {
          total_price: this.selectedGoodsPriceInfo.totalPriceWithVat,
          checkout_option: {
            origin: 'gabia',
          },
          settlement_option: {
            dep_item: 'MS2',
          },
          service: {
            seqno: this.serviceId,
          },
          add_goods_list: willAddedGoodsList,
        },
      });

      return orderData;
    },
    blockUser() {
      alert('잘못된 접근입니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
    },
  },
});
