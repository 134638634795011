function log(text, name) {
  if (process.env.VUE_APP_MODE !== 'production') {
    if(name){
      console.group(`%c ===${name} 시작===`, 'background: black; color: #bada55; font-size: 12px');
    }
    if(['string', 'number'].includes(typeof text)) {
      console.log(`%c ${text}`, 'background: black; color: #bada55; font-size: 22px');
    }else{
      console.log(text);
    }
    if(name){
      console.groupEnd();
    }
  }
};

function install(Vue) {
  if (!Vue.prototype.$console) {
    Object.defineProperty(Vue.prototype, '$console', {
      get() {
        return {
          log
        };
      },
    });
  }
}

export const _console = {
  log
};

export default {
  install,
};
