<template>
  <div class="step_breadcrumb">
    <div class="step" v-for="(step, idx) in stepArr" :key="`step_breadcrumb-${idx}`">
      <span class="numbar" :class="{ active: step.active }">{{ idx + 1 }}</span>
      <p>{{ step.title }}</p>
    </div>
  </div>
</template>
<script>
import { RouterType } from '@/router/types';
import { ApplyType } from '@/types/common/index';
import { StepBreadList } from '@/assets/data/common/constants';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      isCharge: false,
      isInformationStep: false,
      isFinishStep: false,
    };
  },
  computed: {
    stepArr() {
      return this.$store.state.stepInfo.stepArr;
    },
  },
  mounted() {
    // 배포됐을때 url 마지막에 / 붙기 때문에 주의해야한다.
    const routeName = this.$route.name?.split('-');
    // 현재가 신청인지 충전인지 구분하는 변수
    const type = routeName[0];
    // console.log(pathArr);
    const step = routeName[1];

    if (this.$route.params?.service_type === RouterType.CLOUD) {
      this.$store.dispatch('initStepStaus', ApplyType.CLOUD);
    }

    if (this.$route.params?.service_type === RouterType.NIMS) {
      if (this.$route.name.includes('modify')) {
        this.$store.dispatch('initStepStaus', ApplyType.NIMS_MODIFY);
      } else if (this.$route.name.includes('ems')) {
        this.$store.dispatch('initStepStaus', ApplyType.NIMS_EMS);
      } else {
        this.$store.dispatch('initStepStaus', ApplyType.NIMS);
      }
    }

    // 추가/변경 이라면 관리자 정보 입력 페이지가 따로 없으므로
    // if (type === RouterType.ADD) stepArr.splice(2, 1);
    if (type === RouterType.TERMINATION || type === RouterType.CHANGE_PERIOD) {
      // 1단계
      if (Number(step) === 1) {
        this.$store.commit('setStepInfo', {
          ...this.$store.state.stepInfo,
          stepArr: [this.stepArr[0], this.stepArr[3]],
        });
      }
      // 2단계
      else if (Number(step) === 2) {
        this.$store.commit('setStepInfo', {
          ...this.$store.state.stepInfo,
          stepArr: [StepBreadList[ApplyType.OFFICE_365][0], StepBreadList[ApplyType.OFFICE_365][3]],
        });
      }
    }

    this.$store.commit('setStepInfo', {
      ...this.$store.state.stepInfo,
      stepArr: [
        ...this.stepArr.map((stepItem, idx) => {
          if (idx === step - 1)
            return {
              ...stepItem,
              active: true,
            };
          return stepItem;
        }),
      ],
    });
  },
});
</script>
<style scoped lang="scss">
.step_breadcrumb {
  display: flex;
  align-items: center;
  text-align: center;
  width: 940px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;

  @include mq('desktop-wide', 'max') {
    width: 100%;
  }

  .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 0 0;
    .numbar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: #999;
      border-radius: 50%;
      color: #fff;
      font-size: 14px;
      margin-bottom: 5px;
      &.active {
        background: var(--blue);
        & + p {
          color: var(--blue);
        }
      }
    }

    p {
      @include mq('tablet', 'max') {
        font-size: 13px;
      }
    }

    &:after {
      position: absolute;
      display: block;
      content: '';
      font-family: 'Font Awesome 5 Pro';
      font-size: 18px;
      color: #707070;
      top: 0;
      right: 0;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
</style>
