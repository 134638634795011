import Vue from 'vue';
import VueRouter from 'vue-router';
import Step01 from '../pages/Step01Page.vue';
import Step02 from '../pages/Step02Page.vue';
import Step03 from '../pages/Step03Page.vue';
import ModifyStepO1 from '../pages/ModifyStep01Page.vue';
import ModifyStepO2 from '../pages/ModifyStep02Page.vue';
import EmsStepO1 from '../pages/EmsStep01Page.vue';
import EmsStepO2 from '../pages/EmsStep02Page.vue';

import { _console } from '@/plugins/Console';
import { multiNavigationGuards, checkMobileAuth, checkUseCloudService, checkAuth } from './helper';
import { PG_LINKS } from './fragments/external';
import { MS365LINKS } from './fragments/ms365';
import { GUIDE_LINKS } from './fragments/guide';
import { NIMS_LINKS } from './fragments/nims';

Vue.use(VueRouter);

/**
 * 공통 router 정보만 바로 기입하는 방식으로 진행하면 좋을것 같습니다.
 * ex) 신청 1 ~ XX 단계 ..
 * service_type : cloud , nims
 * 그 외 정보는  query param으로 받음
 */
let routes = [
  ...PG_LINKS,
  ...MS365LINKS,
  {
    path: '/apply-step-01/:service_type',
    name: `apply-01`,
    component: Step01,
    beforeEnter: multiNavigationGuards([checkAuth, checkUseCloudService]),
  },
  {
    path: '/apply-step-02/:service_type',
    name: `apply-02`,
    component: Step02,
    beforeEnter: multiNavigationGuards([checkAuth, checkMobileAuth, checkUseCloudService]),
  },
  {
    path: '/apply-step-03/:service_type',
    name: `apply-03`,
    component: Step03,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
  {
    path: '/modify-step-01/:service_type',
    name: `modify-01`,
    component: ModifyStepO1,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
  {
    path: '/modify-step-02/:service_type',
    name: `modify-02`,
    component: ModifyStepO2,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
  {
    path: '/ems-step-01/:service_type',
    name: `ems-01`,
    component: EmsStepO1,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
  {
    path: '/ems-step-02/:service_type',
    name: `ems-02`,
    component: EmsStepO2,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
  {
    path: '*',
    name: 'not-found',
  },
] as Array<{
  path: string;
  name: string;
  component: any;
  beforeEnter?: any;
}>;

/**
 * 개발중 & 개발 편의성 경로는 여기서 추가합니다.
 */
if (process.env.VUE_APP_MODE !== 'production') {
  routes = routes.concat(GUIDE_LINKS);
}

routes = routes.concat(NIMS_LINKS);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  _console.log(to);
  if (to.name === 'not-found') window.location.href = `${process.env.VUE_APP_GABIA_URL}/error_page/err_404.html`;
  next();
});

export default router;
