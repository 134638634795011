import { StepBreadType, PackageIdType } from '@/types/common/index';
import { STEP_MESSAGE } from './messages';

const COMMON_STEPS = [
  {
    title: STEP_MESSAGE.APPLY_INFO_INSERT,
    active: false,
  },
  {
    title: STEP_MESSAGE.SERVICE_PAYMENT,
    active: false,
  },
  {
    title: STEP_MESSAGE.ADMIN_INFO_INSERT,
    active: false,
  },
  {
    title: STEP_MESSAGE.INFO_CHECK,
    active: false,
  },
];

export const StepBreadList: StepBreadType = {
  Office365: COMMON_STEPS,
  Nims: COMMON_STEPS,
  NimsModify: [
    {
      title: STEP_MESSAGE.MODIFY_PRODUCT_SELECT,
      active: false,
    },
    {
      title: STEP_MESSAGE.APPLY_INFO_INSERT,
      active: false,
    },
  ],
  NimsEms: [
    {
      title: STEP_MESSAGE.REFUND_INFO_INSERT,
      active: false,
    },
    {
      title: STEP_MESSAGE.INSERT_INFO_CHECK,
      active: false,
    },
  ],
  Cloud: [
    {
      title: STEP_MESSAGE.APPLY_INFO_INSERT,
      active: false,
    },
    {
      title: STEP_MESSAGE.SERVICE_PAYMENT,
      active: false,
    },
    {
      title: STEP_MESSAGE.INFO_CHECK,
      active: false,
    },
  ],
};

export const PackageIdList: PackageIdType = {
  Office365: [49907, 49908],
};
