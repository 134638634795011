const billingPageURL = process.env.VUE_APP_BILLING_URL;

export enum YesOrNo {
  Yes = 'Y',
  No = 'X',
}

interface Params {
  ordernum: string;
  nologin_payment?: YesOrNo;
  nobatch_payment?: 'X' | 'Y'; //Y-자동결제,수동결제 선택, N-자동결제만 함, X-자동결제 없음
  return_url: string;
  back_url: string;
  payment_flag?: string;
}

export default function moveToBillingPage(params: Params) {
  const form = document.createElement('form');

  form.setAttribute('method', 'post');
  form.setAttribute('action', `${billingPageURL}/payments`);

  Object.entries(params).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', value);
    form.appendChild(input);
  });

  document.body.appendChild(form);

  form.submit();
}
