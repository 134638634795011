
import Vue, { PropType } from 'vue';
import { GoodsPriceInfo } from '@/api/goods/types';

type GoodsPriceEstimateInfo = GoodsPriceInfo & {
  carve_code: string;
  carve_code_str: string;
  domain: string;
  goods_lists: {
    type: string;
    goods_name: string;
    unit_price: number;
    quantity: number;
    goods_order_term_display: string;
    origin_supply_price: number;
    volume: string;
    volume_unit: string;
    pricing_rule_list: {
      display_rule_name: string;
      display_value: string;
      supply_rule_price: number;
    }[];
  }[];
};

type IntegratedEstimateElement = Element & {
  __vue__: {
    getEstimateData: (args: object) => void;
  };
};

export default Vue.extend({
  props: {
    isCalulatePrice: {
      type: Boolean,
      default: true,
    },
    isToBeAnnounced: {
      type: Boolean,
      default: false,
    },
    isPreviousPageLinkBtn: {
      type: Boolean,
      default: false,
    },
    goodsPriceInfo: Object as PropType<{
      totalPrice: number | undefined;
      totalPriceWithVat: number | undefined;
      vat: number | undefined;
      selectedGoods: GoodsPriceEstimateInfo[] | undefined;
      cutPrice: number | undefined;
      totalRulePrice: number | undefined;
    }>,
    /*
      견적서 출력시 domain 정보가 필요한 경우가 있다. (API 결과값에서 비어있는 경우)
      이때 입력창에서 검증된 도메인을 견적서에서 표시할 필요가 있어서 해당 props를 추가
      */
    domain: {
      type: String,
      default: '',
    },
    isEstimate: {
      type: Boolean,
      default: true,
    },
    paymentExpiryDate: {
      type: Number,
      default: 15,
    },
    notMinusPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalPriceWithVat(): string {
      if (this.goodsPriceInfo.selectedGoods?.length === 0) {
        return '0';
      }
      this.goodsPriceInfo.selectedGoods?.[0].price.origin_supply_price;
      this.goodsPriceInfo.selectedGoods?.[0].price.extra_days_origin_supply_price;
      this.goodsPriceInfo.selectedGoods?.[0].total_pricing.total_price;
      const totalPriceWithVat = this.goodsPriceInfo.totalPriceWithVat
        ? this.goodsPriceInfo.totalPriceWithVat.toLocaleString()
        : '0';
      return totalPriceWithVat;
    },
    totalExtraDaysFinalSupplyPrice(): string {
      if (this.goodsPriceInfo.selectedGoods?.length === 0) {
        return '0';
      }

      let returnValue = 0;

      this.goodsPriceInfo.selectedGoods?.forEach((item) => {
        returnValue += item?.price?.extra_days_final_supply_price || 0;
      });

      if (!returnValue) {
        return '0';
      } else {
        return returnValue.toLocaleString();
      }
    },
  },
  methods: {
    handleNextClick() {
      this.$emit('next-step-btn-click');
    },
    handlePrevClick() {
      this.$emit('prev-step-btn-click');
    },
    handleEstimateClick() {
      /*
        TODO: 다른 서비스 견적서 연동할때 해당 부분 분기가 일어날수도 있음.
        보통 받은 API를 그대로 넘겨주면 되지만, 도메인 정보를 사용자에게 입력 받아야하는 예외사항이 있었음.
        추후, 서비스에 따라 도메인을 여러개 받아야할 경우 예외처리가 필요할 수 있음.
      */
      const goodsPriceInfo = JSON.parse(JSON.stringify(this.goodsPriceInfo));
      const selectedGoods = (goodsPriceInfo.selectedGoods || []).map((goods) => ({
        ...goods,
        domain: goods.domain || this.domain,
      }));

      delete goodsPriceInfo.selectedGoods;

      const estimate = {
        type: 'goods',
        payment_expiry_date: this.paymentExpiryDate,
        total_pricing: {
          total_price: this.goodsPriceInfo.totalPrice,
          total_rule_price: this.goodsPriceInfo.totalRulePrice,
          cut_price: this.goodsPriceInfo.cutPrice,
          vat: this.goodsPriceInfo.vat,
          total_price_with_vat: this.goodsPriceInfo.totalPriceWithVat,
        },
        goods_pricing_list: selectedGoods || [],
      };

      const $estimateApp = document.querySelector('.estimate-modal') as IntegratedEstimateElement;
      $estimateApp?.__vue__.getEstimateData(estimate);
    },
  },
});
