/**
 *  NICE 본인인증 실패 Callback URL 과 같이 외부 PG 와 관련된 링크
 */
export const PG_LINKS = [
  {
    path: '/nice-success',
    name: 'nice-success',
    component: () => import(/* webpackChunkName: 'nice-success' */ '@/pages/external/NiceSuccess.vue'),
  },
  {
    path: '/nice-error',
    name: 'nice-error',
    component: () => import(/* webpackChunkName: 'nice-error' */ '@/pages/external/NiceError.vue'),
  },
];
