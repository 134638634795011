import { render, staticRenderFns } from "./Step01Page.vue?vue&type=template&id=4773269a&scoped=true"
import script from "./Step01Page.vue?vue&type=script&lang=js"
export * from "./Step01Page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4773269a",
  null
  
)

export default component.exports