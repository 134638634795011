
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { Console, Gtris, GlobalLoading } from '@/plugins';
import '@/assets/scss/style.scss';

Vue.use(VueMeta);
Vue.use(Gtris);
Vue.use(Console);
Vue.use(GlobalLoading);

export default Vue.extend({
  created() {
    const scriptEl = document.createElement('script');
    const today = new Date();

    const dateStr = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}-${today.getHours()}`;

    scriptEl.src = `${process.env.VUE_APP_GNB_FOOTER_URL}/main.js?d=${dateStr}`;
    scriptEl.type = 'text/javascript';

    document.body.appendChild(scriptEl);

    // 견적서 관련 스크립트 불러오기
    const $estimateScript = document.createElement('script');
    $estimateScript.src = `${process.env.VUE_APP_ESTIMATE_URL}/main.js?d=${dateStr}`;
    scriptEl.type = 'text/javascript';
    document.body.appendChild($estimateScript);
  },
  metaInfo: {
    titleTemplate: '가비아 - %s',
  },
});
