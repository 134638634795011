import Vue from 'vue';
import store from '@/store';
import { AxiosError } from 'axios';
import { _console } from '@/plugins/Console';
import { getCookie, maintenanceAlert } from '@/utils/common.js';
import { getCertifyUser } from '@/api/cloud/index';
import { HttpStatusCode } from '@/types/common/index';
import { getGabiaUserInfo } from '@/api/gabia-user';

const MESSAGE_OBJ = {
  maintenanceCloud:
    '가비아 클라우드 시스템 점검 중입니다.(8/22(목) 오전 01:00 ~ 오전 02:00)\n작업 시간 중 g클라우드 이용 신청이 불가합니다. 점검 시간 이후 이용 신청 바랍니다. 공지사항을 확인해주세요. 감사합니다.',
  otherNotice: '시스템 이용에 문제가 발생하였습니다.\n가비아 고객센터(1544-4370)로 연락하시기 바랍니다.\n감사합니다.',
};

export const multiNavigationGuards = (funcs: Array<(...functionArgs: any) => any>) => {
  return async (to, from, next) => {
    Vue.prototype.$GlobalLoading.show();
    let canMoveNext = true;

    for (let i = 0; i < funcs.length; i++) {
      const func = funcs[i];
      const argArr = [to, from, next];
      const returnValue = await func(...argArr.splice(0, func.length));
      if (!returnValue) {
        canMoveNext = false;
        break;
      }
    }

    if (canMoveNext) next();
  };
};

export const pascalCaseToSnakeCase = (word: string) => {
  return word.replace(/([A-Z])/g, (match) => {
    return `_${match.toLowerCase()}`;
  });
};

export const checkMobileAuth = (to, from, next) => {
  const SERVICE_TYPE_ARR = ['cloud']; // 체크할 service_type
  const SERVICE_TYPE_RETURN_ARR = ['apply-01']; // 실패할 경우 돌려보낼 routerName (index 주의)

  const service_type = to.params.service_type;

  //! 페이지 새로 고침대비 (임의값을 집어넣어도 페이지 통과는 되어도 어차피 인증이 불가)
  if (SERVICE_TYPE_ARR.includes(service_type) && to.query.auth_sess) {
    store.dispatch('setAuthSess', to.query.auth_sess);
    store.dispatch('setMobileAuth', true);
    return true;
  }

  if (SERVICE_TYPE_ARR.includes(service_type) && !store.state.isMobileAuth) {
    alert('본인확인을 거치지 않으면 해당페이지에 접근이 불가 합니다.');
    next({
      name: SERVICE_TYPE_RETURN_ARR[SERVICE_TYPE_ARR.indexOf(service_type)],
      params: { service_type: service_type },
    });
    return false;
  } else {
    return true;
  }
};

export const checkServiceCode = (to, from, next) => {
  const serviceCode = to.params.service_code;
  if (serviceCode === 'msoffice365') {
    return setStoreWithQuery('type')(to, from, next);
  }
  return true;
};

export const setStoreWithQuery = (type) => {
  return function (to, from, next) {
    if (typeof type === 'object') {
      type.forEach((t) => {
        const queryValue = to.query[pascalCaseToSnakeCase(t)];
        if (!queryValue) {
          _console.log(`${pascalCaseToSnakeCase(t)}가 없음`);
          return false;
        }

        store.commit('setGoodsInfo', {
          ...store.state.goodsInfo,
          [`${t}`]: Number(queryValue) ? Number(queryValue) : queryValue,
        });
      });
    } else {
      const queryValue = to.query[pascalCaseToSnakeCase(type)];
      _console.log(queryValue, 'pascal');
      if (!queryValue) {
        _console.log(`${pascalCaseToSnakeCase(type)}가 없음`);
        return false;
      }

      store.commit('setGoodsInfo', {
        ...store.state.goodsInfo,
        [`${type}`]: Number(queryValue) ? Number(queryValue) : queryValue,
      });
    }

    return true;
  };
};

export const login = () => {
  let url = process.env.VUE_APP_GABIA_URL;

  url += '/login/login_form';

  const arr = window.location.search.split('?').join('').split('&');
  let href = '';
  if (arr.length >= 2) {
    console.log('multi data');
    href = `${url}?origin_site=${window.location.host}&target_url=${window.location.pathname}?${arr
      .join('%26')
      .split('=')
      .join('%3d')}`;
  } else {
    console.log('not multi data');
    href = `${url}?origin_site=${window.location.host}&target_url=${window.location.pathname}${window.location.search}`;
  }

  window.location.href = href;
};

/**
 * 클라우드 서비스 신청단계에서 이미 신청한 유저인지를 판별하는 함수
 * 200 : 미가입 상태 -> 신청 페이지로 보냄
 * 409 : 이미 가입하여 신청페이지에서 튕겨내야하는 경우 -> 클라우드 콘솔로 리다이렉트
 * 그 외 에러 : 조치 불가 에러로 에러 문구만 노출하고 가비아 메인페이지로 보냄
 * @returns boolean
 */
export const checkUseCloudService = async (to, from, next) => {
  try {
    if (to.params?.service_type !== 'cloud') {
      //! 클라우드 서비스만 체크함
      return true;
    }

    if (
      !maintenanceAlert(
        '2024-08-22T01:00:00',
        '2024-08-22T02:00:00',
        'https://www.gabia.com/gabia_notice/view.php?seq_no=33622&page=1&gubun=&keyword=',
        MESSAGE_OBJ,
        'maintenanceCloud',
      )
    ) {
      return false;
    }

    const gasession = getCookie('gasession');
    if (!gasession) {
      alert('gass-error');
      return false;
    }
    const { status } = await getCertifyUser(gasession);

    if (HttpStatusCode.OK === status) {
      return true;
    }
  } catch (error) {
    const errorResponse = (error as AxiosError)?.response;

    if (errorResponse?.status === HttpStatusCode.CONFLICT) {
      //! 현재 클라우드 서비스 가입중
      window.location.href = process.env.VUE_APP_CLOUD_URL;
    } else {
      const message = errorResponse?.data?.description || '불특정 에러가 발생했습니다.';
      alert(message);
      window.location.href = process.env.VUE_APP_GABIA_URL;
    }

    return false;
  }
};

export async function checkAuth(to, from, next) {
  const userInfo = await getGabiaUserInfo();
  store.commit('setUserInfo', userInfo);
  if (!userInfo) {
    //TODO 로그인 페이지로 redirect
    console.log(process.env.VUE_APP_MODE);
    if (process.env.VUE_APP_MODE !== 'production') {
      if (confirm('test: 로그인을 하지 않고 페이지 접근할까요? ')) {
        return true;
      } else {
        login();
      }
    } else {
      login();
    }
  } else {
    return true;
  }
}
