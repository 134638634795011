
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    onCloseBtnClick() {
      console.log('click');
      this.isActive = false;
    },
  },
});
