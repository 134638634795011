import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { State } from './types';
import { ApplyType } from '@/types/common/index';
import { StepBreadList } from '@/assets/data/common/constants';
Vue.use(Vuex);

const store: StoreOptions<State> = {
  state: {
    userInfo: undefined,
    goodsInfo: undefined,
    terminationInfo: undefined,
    stepInfo: {
      stepArr: StepBreadList[ApplyType.OFFICE_365],
    },
    changePeriod: undefined,
    isMobileAuth: false,
    cloudAuthSess: '',
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setGoodsInfo(state, payload) {
      state.goodsInfo = {
        ...state.goodsInfo,
        ...payload,
      };
    },
    setTerminationInfo(state, payload) {
      state.terminationInfo = payload;
    },
    setStepInfo(state, payload) {
      state.stepInfo = payload;
    },
    setChangePeriod(state, payload) {
      state.changePeriod = payload;
    },
    setMobileAuth(state, payload: boolean) {
      state.isMobileAuth = payload;
    },
    setAuthSess(state, payload: string) {
      state.cloudAuthSess = payload;
    },
  },
  actions: {
    initStepStaus(state, type: ApplyType) {
      state.commit('setStepInfo', { stepArr: StepBreadList[type] });
    },
    setMobileAuth(state, isCurrect: boolean) {
      state.commit('setMobileAuth', isCurrect);
    },
    setAuthSess(state, authsess: string) {
      state.commit('setAuthSess', authsess);
    },
  },
};

export default new Vuex.Store(store);
