import { proxyAPI } from '../instance';
import { PROXYALIAS } from '../types';
import { CreatedOrderInfo, CreateNewOrderParams, OrderInfo, CreateAddOrderParams, OrderPossible } from './types';

const proxyAlias = PROXYALIAS.order;

// 신규 주문서 생성 API
export const createNewOrder = async function (paramsData: CreateNewOrderParams) {
  const params = {
    url: '/v5/application/order',
    method: 'POST',
    data: paramsData,
  };

  return (await proxyAPI.post(`/auth/${proxyAlias}`, params)) as CreatedOrderInfo | null;
};

// 추가/변경 주문서 생성 API
export const createAddOrder = async function (paramsData: CreateAddOrderParams) {
  const params = {
    url: '/v5/add-change/order',
    method: 'POST',
    data: paramsData,
  };

  return (await proxyAPI.post(`/auth/${proxyAlias}`, params)) as CreatedOrderInfo | null;
};

export const getOrderInfo = async function (payment_seq: number) {
  const params = {
    url: `/v5/payment/${payment_seq}`,
    'data[response_option[with_goods_info]]': 'Y',
  };

  return (await proxyAPI.get(`/auth/${proxyAlias}`, {
    params,
  })) as OrderInfo | null;
};

/*
 * @reference https://confluence.gabia.com/pages/viewpage.action?pageId=65401200
 * @description 서비스 주문서 종류별 생성 가능 여부 체크 API
 * @params {number} 통합 서비스 시퀀스
 * @return {Promise}
 */
export const getOrderPossible = async function (integrated_service_seqno: number) {
  const params = {
    url: `/v5/service/${integrated_service_seqno}/order-possible`,
    'data[order_dep_item_list[]]': 'MS1',
  };

  return (await proxyAPI.get(`/auth/${proxyAlias}`, {
    params,
  })) as OrderPossible | null;
};

//성공 예시

// {
//   order_page: 'application',
//   checkout: {
//     goods_list: [
//       {
//         id: 51892,
//         quantity: 1,
//         order_term: {
//           unit: 'own',
//           term: 1,
//         },
//         settlement_option: {
//           dep_item: 'HW3_0',
//         },
//       },
//       {
//         id: 52006,
//         quantity: 1,
//         order_term: {
//           unit: 'month',
//           term: 1,
//         },
//         settlement_option: {
//           dep_item: 'HW3_0',
//         },
//       },
//       {
//         id: 52055,
//         quantity: 1,
//         order_term: {
//           unit: 'month',
//           term: 1,
//         },
//         settlement_option: {
//           dep_item: 'HW3_0',
//         },
//       },
//     ],
//     domain_data: {
//       domain: 'testetstmoon.onhiworks.com',
//     },
//     checkout_option: {
//       origin: 'hiworks',
//       order_number_option: {
//         prefix: '',
//         suffix: '',
//       },
//     },
//     total_price: 253000,
//   },
// }
