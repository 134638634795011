
import Vue from 'vue';
import { getServiceInfo } from '@/api/proxy/service';
import { ServiceMainGoods } from '@/api/proxy/service/types';

export default Vue.extend({
  components: {
    MicrosoftAdminInfoAfterBrain: () => import('@/Brain/ms365/MicrosoftAdminInfoAfterBrain.vue'),
  },
  data() {
    return {
      carveCode: '',
      targetServiceInfo: {} as ServiceMainGoods | undefined,
    };
  },
  metaInfo: {
    title: '관리 정보 입력',
  },
  async created() {
    const integratedServiceSeqno = this.$store.state.goodsInfo.integratedServiceSeqno; // integrated_service_seqno(통합 서비스 숫자)

    if (integratedServiceSeqno) {
      const getService = await getServiceInfo(integratedServiceSeqno);

      if (!getService || this.$store.state.userInfo.user_id !== getService?.service_user?.id) {
        alert('잘못된 접근입니다.');
        window.location.href = process.env.VUE_APP_GABIA_URL;
      }

      const targetServiceInfo = getService?.service_main_goods_list.find(
        (service) => service.gabia_service_seqno === integratedServiceSeqno,
      );

      this.carveCode = targetServiceInfo?.carve_code || '';
      this.targetServiceInfo = targetServiceInfo;
    }
  },
});
