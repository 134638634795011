<template>
  <div>
    <step-bread-crumb />
    <cloud-step-02-brain v-if="isCloudStep"></cloud-step-02-brain>
  </div>
</template>

<script>
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    CloudStep02Brain: () => import('@/Brain/cloud/CloudStep02Brain.vue'),
    StepBreadCrumb,
  },
  data() {
    return {};
  },
  computed: {
    packageId() {
      return this.$store.state.goodsInfo?.packageId;
    },
    isCloudStep() {
      /**
       * 클라우드는 신청 1 ~ 3단계 이며 결제수단만 등록하고 실제 상품신청은 관리콘솔(외부페이지)에서 설정하므로 packageId 등이 없습니다.
       * 해당 조건식은 바뀔수 있어 computed로 분리
       */
      return this.packageId === undefined && this.$route.params.service_type === 'cloud';
    },
  },
  metaInfo: {
    title: '신청 2단계',
  },
  created() {
    this.$console.log(this.$route, 'step02-page-route');
  },
});
</script>

<style scoped></style>
