<template>
  <div>
    <step-bread-crumb />
    <microsoft-add-01-brain />
  </div>
</template>

<script>
import MicrosoftAdd01Brain from '@/Brain/ms365/MicrosoftAdd01Brain.vue';
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    StepBreadCrumb,
    MicrosoftAdd01Brain,
  },
  computed: {
    goodsInfo() {
      return this.$store.state.goodsInfo;
    },
    id() {
      return this.goodsInfo?.id;
    },
  },
  metaInfo: {
    title: '추가 1단계',
  },
  created() {
    this.$console.log(this.$route, 'step01-page-route');
  },
});
</script>

<style scoped></style>
