<template>
  <div>
    <step-bread-crumb />
    <microsoft-change-period-01-brain v-if="isMsOffice365Service" />
  </div>
</template>

<script>
import Vue from 'vue';
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';

export default Vue.extend({
  components: {
    StepBreadCrumb,
    MicrosoftChangePeriod01Brain: () => import('@/Brain/ms365/MicrosoftChangePeriod01Brain.vue'),
  },
  computed: {
    isMsOffice365Service() {
      return this.$route.params.service_code === 'msoffice365';
    },
  },
  metaInfo: {
    title: '이용 기간 변경 1단계',
  },
});
</script>

<style scoped></style>
