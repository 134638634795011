
import Vue, { PropType } from 'vue';
import { uuid } from '../utils';

export default Vue.extend({
  model: {
    prop: 'checked',
    event: 'checked',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: [String, Number],
      required: false,
    },
    checked: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
  },
  computed: {
    id() {
      return uuid();
    },
  },
  methods: {
    onChange(e: Event) {
      const target = e.target as HTMLInputElement;
      this.$emit('checked', target.checked);
      this.$emit('change', {
        checked: target.checked,
        name: this.name,
      });
    },
  },
});
