<template>
  <div>
    <step-bread-crumb />
    <microsoft-termination-01-brain
      v-if="$route.params.service_code === 'msoffice365'"
    ></microsoft-termination-01-brain>
  </div>
</template>

<script>
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    StepBreadCrumb,
    MicrosoftTermination01Brain: () => import('@/Brain/ms365/MicrosoftTermination01Brain.vue'),
  },
  metaInfo: {
    title: '해지 1단계',
  },
});
</script>

<style scoped></style>
