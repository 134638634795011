/**
 * microsoft365 에서만 전용으로 사용하는 라우터 모음
 */
import Add01 from '@/pages/ms365/Add01Page.vue';
import Termination01 from '@/pages/ms365/Termination01Page.vue';
import TerminationResult from '@/pages/ms365/TerminationResultPage.vue';
import AdminInfoAfter from '@/pages/ms365/AdminInfoAfterPage.vue';
import ChangePeriod from '@/pages/ms365/ChangePeriodPage.vue';
import ChangePeriodResult from '@/pages/ms365/ChangePeriodResultPage.vue';
import Step01 from '@/pages/Step01Page.vue';
import Step03 from '@/pages/Step03Page.vue';
import { RouterType } from '../types';
import { multiNavigationGuards, checkServiceCode, setStoreWithQuery, checkAuth } from '../helper';

export const MS365LINKS = [
  {
    path: '/application',
    name: `${RouterType.APPLICATION}-01`,
    component: Step01,
    beforeEnter: multiNavigationGuards([checkAuth, setStoreWithQuery('packageId')]),
  },
  {
    path: '/management-information',
    name: `${RouterType.APPLICATION}-03`,
    component: Step03,
    beforeEnter: multiNavigationGuards([checkAuth, setStoreWithQuery('serviceId')]),
  },
  {
    path: '/management-information-after',
    name: 'management-information-after',
    component: AdminInfoAfter,
    beforeEnter: multiNavigationGuards([checkAuth, setStoreWithQuery('integratedServiceSeqno')]),
  },
  {
    path: '/add-goods',
    name: `${RouterType.ADD}-01`,
    component: Add01,
    beforeEnter: multiNavigationGuards([checkAuth, setStoreWithQuery(['serviceId', 'goodsId'])]),
  },
  {
    path: '/termination/:service_code',
    name: `${RouterType.TERMINATION}-01`,
    component: Termination01,
    beforeEnter: multiNavigationGuards([checkAuth, checkServiceCode, setStoreWithQuery(['serviceId'])]),
  },
  {
    path: '/termination/:service_code/result',
    name: `${RouterType.TERMINATION}-02`,
    component: TerminationResult,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
  {
    path: '/change-period/:service_code',
    name: `${RouterType.CHANGE_PERIOD}-01`,
    component: ChangePeriod,
    beforeEnter: multiNavigationGuards([checkAuth, setStoreWithQuery(['integratedServiceSeqno'])]),
  },
  {
    path: '/change-period-result/:service_code',
    name: `${RouterType.CHANGE_PERIOD}-02`,
    component: ChangePeriodResult,
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
];
