import { render, staticRenderFns } from "./FormLayout.vue?vue&type=template&id=a45a6102&scoped=true"
import script from "./FormLayout.vue?vue&type=script&lang=ts"
export * from "./FormLayout.vue?vue&type=script&lang=ts"
import style0 from "./FormLayout.vue?vue&type=style&index=0&id=a45a6102&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a45a6102",
  null
  
)

export default component.exports