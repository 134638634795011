
import Vue from 'vue';
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import { getOrderInfo } from '@/api/proxy/order';
import { RouterType } from '@/router/types';
import { OrderInfo } from '@/api/proxy/order/types';

export default Vue.extend({
  components: {
    CloudStep03Brain: () => import('@/Brain/cloud/CloudStep03Brain.vue'),
    MicrosoftStep03Brain: () => import('@/Brain/ms365/MicrosoftStep03Brain.vue'),
    NimsStep03Brain: () => import('@/Brain/nims/NimsStep03Brain.vue'),
    NimsStep03TechBrain: () => import('@/Brain/nims/NimsStep03TechBrain.vue'),
    StepBreadCrumb,
  },
  metaInfo: {
    title: '관리 정보 입력',
  },
  data() {
    return {
      carve_code: '',
      orderInfo: {} as OrderInfo | null,
    };
  },

  computed: {
    isOffice365Step(): boolean {
      return this.carve_code === 'msoffice365';
    },
    isCloudStep(): boolean {
      return !this.carve_code && !this.$store.state.goodsInfo && this.$route.params.service_type === RouterType.CLOUD;
    },
    isNimsStep(): boolean {
      const PAGE_TYPE = this.$route?.query?.type || '';
      return this.$route.params.service_type === RouterType.NIMS && !PAGE_TYPE;
    },
    isNimsTechStep(): boolean {
      const PAGE_TYPE = this.$route?.query?.type || '';
      return this.$route.params.service_type === RouterType.NIMS && PAGE_TYPE === 'tech';
    },
  },

  async created() {
    const goodsInfo = this.$store.state.goodsInfo;

    if (!goodsInfo) {
      // cloud 신청 단계
      return;
    }

    if (goodsInfo.serviceId) {
      const orderInfo = await getOrderInfo(goodsInfo.serviceId);
      const carve_code = orderInfo?.settlement_list[0].dep_code.carve_code_string;

      this.carve_code = carve_code || '';
      this.orderInfo = orderInfo;

      // 로그인 사용자와 관리 정보 입력 사용자가 일치하지 않는 경우
      if (this.$store.state.userInfo.user_id !== orderInfo?.user_id) {
        alert('잘못된 접근입니다.');
        window.location.href = process.env.VUE_APP_GABIA_URL;
      }
    }
  },
});
