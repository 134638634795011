import { render, staticRenderFns } from "./Termination01Page.vue?vue&type=template&id=45e8f772&scoped=true"
import script from "./Termination01Page.vue?vue&type=script&lang=js"
export * from "./Termination01Page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e8f772",
  null
  
)

export default component.exports