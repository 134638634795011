import { instance } from '../instance';
import { Info as GoodsInfo, PriceInfo, GetGoodsInfoParams, AddPriceInfo } from './types';

const BASEURL = process.env.VUE_APP_GOODS_API;

const baseHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

const goodsAPI = instance.create(BASEURL, {
  headers: baseHeaders,
});

export function getGoodsInfo(params): Promise<GoodsInfo | null> {
  return goodsAPI.get('/v4/openapi/order/goods', {
    params,
    headers: {
      ...baseHeaders,
      Authorization: 'GIAK f59c47d3578bec6d37a5e9b8cba7c21gs',
    },
  });
}

export function getGoodsPrice(params): Promise<PriceInfo | null> {
  return goodsAPI.get('/v4/goods/pricing/calculate', {
    params,
  });
}

export function getAddGoodsPrice(params): Promise<AddPriceInfo | null> {
  return goodsAPI.get('/v4/goods/pricing/add-change', {
    params,
  });
}
