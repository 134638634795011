<template>
  <div>
    <step-bread-crumb />
    <microsoft-termination-result-brain v-if="$route.params.service_code === 'msoffice365'" />
  </div>
</template>

<script>
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    StepBreadCrumb,
    MicrosoftTerminationResultBrain: () => import('@/Brain/ms365/MicrosoftTerminationResultBrain.vue'),
  },
  metaInfo: {
    title: '해지 완료',
  },
});
</script>

<style scoped></style>
