import { instance } from '../instance';
import { AxiosPromise } from 'axios';
import { CloudCertifyCheck, CloudGabiaAuth, CloudRegist } from './types';

const BASEURL = process.env.VUE_APP_CLOUD_API;

const OPTIONS = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
  },
};

const cloudAPI = instance.createWithoutInterceptors(BASEURL, OPTIONS);

/** 클라우드 신청 가능 불가 여부 체크 */
export function getCertifyUser(gasess: string): AxiosPromise<CloudCertifyCheck> {
  return cloudAPI.get(`/resource/api/v1/certify?gasess=${gasess}`);
}

/** 가비아 소유자 인증 초기화 값 */
export function getCertifyGabiaAuth(): AxiosPromise<CloudGabiaAuth> {
  const successUrl = encodeURIComponent(`${process.env.VUE_APP_APPLICATION_URL}/nice-success`);
  const errorUrl = encodeURIComponent(`${process.env.VUE_APP_APPLICATION_URL}/nice-error`);

  return cloudAPI.get(`/cloud/api/certify/initphone?succ_url=${successUrl}&fail_url=${errorUrl}`);
}

/** 클라우드 서비스 가입 (status 코드만 사용할 예정이므로 return 정보는 any 로 처리) */
export function createCloudServiceInfo(param: CloudRegist): AxiosPromise<any> {
  return cloudAPI.post(`/resource/api/v1/users`, param);
}
