<template>
  <div>
    <step-bread-crumb />
    <NimsEmsStep01Brain />
  </div>
</template>

<script>
import StepBreadCrumb from '@/components/common/StepBreadCrumb.vue';
import { PackageIdList } from '@/assets/data/common/constants';
import { RouterType } from '@/router/types';
import Vue from 'vue';

export default Vue.extend({
  components: {
    NimsEmsStep01Brain: () => import('@/Brain/nims/NimsEmsStep01Brain.vue'),
    StepBreadCrumb,
  },
  data() {
    return {
      office365PackageIdArr: PackageIdList.Office365,
    };
  },
  computed: {
    packageId() {
      return this.$store.state.goodsInfo?.packageId;
    },
    isNimsStep() {
      return this.$route.params.service_type === RouterType.NIMS;
    },
  },
  metaInfo: {
    title: '환불정보 입력 1단계',
  },
});
</script>

<style scoped></style>
