import GtrisCheckbox from './GtrisCheckbox/GtrisCheckbox.vue';
import GtrisRadio from './GtrisRadio/index.vue';
import GtrisPopover from './GtrisPopover/index.vue';
import { GtrisModal, installGtrisModal } from './GtrisModal';

function install(Vue) {
  Vue.component('GtrisCheckbox', GtrisCheckbox);
  Vue.component('GtrisRadio', GtrisRadio);
  Vue.component('GtrisPopover', GtrisPopover);
  Vue.component('GtrisModal', GtrisModal);
  installGtrisModal(Vue);
}

export default {
  install,
};
