import { multiNavigationGuards, checkAuth } from '../helper';

export const NIMS_LINKS = [
  {
    path: '/group-select/:service_type',
    name: `group-select`,
    component: () => import(/* webpackChunkName: 'group-select-page' */ '@/pages/nims/GroupSelectPage.vue'),
    beforeEnter: multiNavigationGuards([checkAuth]),
  },
];
