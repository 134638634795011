<template>
  <div v-show="isActive" ref="container" class="gt-modal-container">
    <div
      ref="overlay"
      :class="[overlayClass, overlayClassObject]"
      @click="closeModal"
    ></div>
    <div ref="modal" class="gt-modal" :class="modalClassObject">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const overlayClass = "gt-modal-overlay";
const modalClass = "gt-modal";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    hideWithHardware: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      overlayClass,
      modalClass,
      overlayActiveClass: `${overlayClass}--active`,
      overlayDeactiveClass: `${overlayClass}--deactive`,
      modalActiveClass: `${modalClass}--active`,
      modalDeactiveClass: `${modalClass}--deactive`,
      isActive: false,
      isDeactive: false,
      isContainerActive: false,
      callback: undefined,
      firstTabStop: undefined,
      lastTabStop: undefined,
      focusedElementBeforeModal: undefined,
    };
  },
  computed: {
    overlayClassObject() {
      return {
        [this.overlayActiveClass]: this.isActive,
        [this.overlayDeactiveClass]: this.isDeactive,
      };
    },
    modalClassObject() {
      return {
        [this.modalActiveClass]: this.isActive,
        [this.modalDeactiveClass]: this.isDeactive,
      };
    },
  },
  mounted() {
    this.$GabiaModal.add(this);
  },
  methods: {
    closeModal() {
      if (!this.hideWithHardware) return;
      this.$GabiaModal.hide();
      this.$emit('close-modal');
    },
    open() {
      this.isActive = true;
      window.addEventListener("resize", this.adjustOverflow);

      const mo = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes) {
            this.findFocusableElements();
            return;
          }
        });
      });

      this.$refs.container.addEventListener("keydown", this.trapTabKey);

      this.$nextTick(() => {
        this.adjustOverflow();

        const config = {
          childList: true,
          subtree: true || null,
        };

        mo.observe(this.$slots.default[0].elm, config);

        this.focusedElementBeforeModal = document.activeElement;
        this.findFocusableElements();
      });
    },
    close() {
      this.isDeactive = true;
      window.removeEventListener("resize", this.adjustOverflow);
      this.$refs.container.removeEventListener("keydown", this.trapTabKey);

      setTimeout(() => {
        this.isActive = false;
        this.isDeactive = false;
        if (this.focusedElementBeforeModal) {
          this.focusedElementBeforeModal.focus();
          this.focusedElementBeforeModal = undefined;
        }
        // 긴 모달일 경우 모달의 scroll 원위치 ie 호환 문제로 임시 주석 처리
        // this.$refs.container.scrollTop = 0;
      }, 410);
    },
    // modal의 길이가 window 높이보다 높을 때
    // 스크롤이 되도록 처리해주는 함수
    adjustOverflow() {
      const height = this.$refs.modal.clientHeight;

      if (height > window.innerHeight) {
        this.$refs.modal.style.cssText = `
            position: absolute;
            top: 20px;
          `;

        this.$refs.overlay.style.cssText = `
            height: ${height + 40}px
          `;

        this.$refs.container.style.cssText = `
            overflow-x: hidden;
            overflow-y: scroll;
          `;
      } else {
        // ie에서 아래와 같이 style에 쓰는 것 허용하지 않는다.
        // this.$refs.modal.style = "";
        this.$refs.modal.style.cssText = "";
        this.$refs.overlay.style.cssText = "";
        this.$refs.container.style.cssText = "";
      }
    },
    findFocusableElements() {
      const focusableElementsString =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
      let focusableElements = this.$refs.container.querySelectorAll(
        focusableElementsString
      );

      this.firstTabStop = focusableElements[0];
      this.lastTabStop = focusableElements[focusableElements.length - 1];

      if (
        !this.$slots.default[0].elm.contains(document.activeElement) &&
        this.firstTabStop
      ) {
        this.firstTabStop.focus();
      }
    },
    trapTabKey(e) {
      if (e.keyCode === 9) {
        if (e.shiftKey) {
          if (document.activeElement === this.firstTabStop) {
            e.preventDefault();
            this.lastTabStop.focus();
          }
        } else {
          if (document.activeElement === this.lastTabStop) {
            e.preventDefault();
            this.firstTabStop.focus();
          }
        }
      }
    },
  },
};
</script>

<style>
@keyframes gt-modal-overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.35;
  }
}
@keyframes gt-modal-overlay-fade-out {
  0% {
    opacity: 0.35;
  }
  100% {
    opacity: 0;
  }
}
@keyframes gt-modal-drop-down {
  0% {
    opacity: 0;
    top: 8%;
  }
  100% {
    opacity: 1;
    top: 10%;
  }
}
@keyframes gt-modal-drop-up {
  0% {
    opacity: 1;
    top: 10%;
  }
  100% {
    opacity: 0;
    top: 8%;
  }
}
@keyframes gt-modal-zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes gt-modal-zoom-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
.gt-modal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2001;
}
.gt-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(51, 51, 51);
}
.gt-modal-overlay--active {
  opacity: 0;
  animation: 0.2s gt-modal-overlay-fade-in ease-out forwards;
}
.gt-modal--active {
  opacity: 0;
  transform: scale(0.9);
  animation: 0.2s gt-modal-zoom-in ease-out forwards;
  animation-delay: 0.2s;
}
.gt-modal-overlay--deactive {
  opacity: 0.35;
  animation: 0.2s gt-modal-overlay-fade-out ease-out forwards;
  animation-delay: 0.2s;
}
.gt-modal--deactive {
  opacity: 1;
  transform: scale(1);
  animation: 0.2s gt-modal-zoom-out ease-out forwards;
}

@media screen and (max-width: 1024px) {
  .gt-modal--active {
    width: 90%;
  }
}
</style>
