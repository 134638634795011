<template>
  <div class="column" :style="columnStyleObject">
    <div class="title" :style="[alignSelfObject]" :class="titleClass">
      <slot name="title"></slot>
    </div>
    <div class="content" :style="[alignSelfObject]" :class="contentClass">
      <slot name="content"></slot>
    </div>
    <div class="content full-content" :class="fullContentClass">
      <slot name="full-content"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    nopadding: {
      type: Boolean,
    },
    alignSelf: {
      type: String,
    },
    titleWidth: {
      type: Number,
    },
    titleClass: {
      type: [],
      default: () => [],
    },
    contentClass: {
      type: [],
      default: () => [],
    },
    fullContentClass: {
      type: [],
      default: () => [],
    },
  },
  computed: {
    columnStyleObject() {
      let styleObj = {
        padding: this.nopadding ? '10px 0' : '',
      };

      if (this.titleWidth) {
        styleObj = {
          ...styleObj,
          'grid-template-columns': `${this.titleWidth}px auto`,
          '-ms-grid-columns': `${this.titleWidth}px 500px`,
        };
      }
      return styleObj;
    },
    alignSelfObject() {
      if (this.alignSelf) {
        return {
          'align-self': `${this.alignSelf}`,
        };
      }
      return {};
    },
  },
});
</script>

<style lang="scss" scoped>
.column {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 240px auto;
  -ms-grid-columns: 240px 500px;
  padding: 25px 40px;
  /* display: flex;
    flex-direction: column;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    -ms-grid-row: 3;
    -ms-gris-row-span: 1;
    grid-row: 2;
    gap: 50px; */

  @include mq('desktop-wide', 'max') {
    display: block;
    padding: 20px 15px;
  }
}

.title {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  width: 100%;
  text-align: start;
  align-self: center;
}

.content {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  text-align: start;
  align-self: center;
}

.full-content {
  grid-column: 1 / -1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  /* display: flex;
  justify-content: start; */
}
</style>
