import GlobalLoading from './GlobalLoading.vue';

export default {
  install(Vue) {
    if (!Vue.prototype.$GlobalLoading) {
      const GlobalLoadingExtend = Vue.extend(GlobalLoading);
      const GlobalLoadingInstance = new GlobalLoadingExtend();

      GlobalLoadingInstance.$mount();
      
      document.body.appendChild(GlobalLoadingInstance.$el);

      Object.defineProperty(Vue.prototype, '$GlobalLoading', {
        get() {
          return {
            show() {
              GlobalLoadingInstance.show();
            },
            hide() {
              GlobalLoadingInstance.hide();
            },
          };
        },
      });
    }
  }
}
