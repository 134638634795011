import { instance } from '../instance';
import { User } from './types';

const BASEURL = process.env.VUE_APP_GABIA_USER_API;

const gabiaUserAPI = instance.create(BASEURL);

gabiaUserAPI.interceptors.response.use((res) => {
  if (!res)
    return {
      data: res,
    };
  else return res;
});

export async function getGabiaUserInfo(): Promise<User | null> {
  return (await gabiaUserAPI.get('/v1/users/me?load_contact=Y')).data;
}
